
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































$bp: l;

.podcast-sticky {
  position: fixed;
  z-index: 2;
  top: var(--header-height-down);
  left: 0;
  width: 100%;
  background: $c-white;
  box-shadow: $card-shadow;
  opacity: 0;
  transform: translateY(-100%);
  transition: transform 0.1s $ease-in, opacity 0s 0.1s;

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.3s $ease-out, opacity 0s;
  }
}

.podcast-sticky-inner {
  display: flex;
  height: var(--podcast-sticky-height);

  @include mq($until: m) {
    padding: 0 !important; // stylelint-disable-line
  }
}

.podcast-sticky__video {
  height: 100%;
  aspect-ratio: 16/9;
  background: $c-light-grey;
}

.podcast-sticky__title {
  @extend %ff-alt;
  @extend %fw-bold;

  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.podcast-sticky__title__content {
  display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
  overflow: hidden;
  margin: 0 $spacing;
  font-size: 1.5rem;
  line-height: 1.2;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix

  ::v-deep em {
    @extend %ff-default;
    @extend %fw-thin;

    font-style: italic;
  }

  @include mq($bp) {
    flex: 1;
    margin: $spacing * 0.5 $spacing * 2;
    font-size: 2.4rem;
  }
}

.podcast-sticky__title__progress {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 0.8rem;
  background: $c-lighter-grey;

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--podcast-progress);
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: $c-mustard;
  }

  @include mq($until: $bp) {
    display: none;
  }
}

.podcast-sticky__platforms {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing * 0.5;
  margin: 0;
  padding: $spacing * 2;
  border-right: 1px solid $c-light-grey;
  border-left: 1px solid $c-light-grey;
  list-style-type: none;

  @include mq($until: $bp) {
    display: none;
  }
}

.podcast-sticky__platforms__item__link {
  display: block;
  transition: transform 0.2s $ease-out;

  span {
    @extend %visually-hidden;
  }

  &:hover,
  &:focus-visible {
    transform: scale(1.1);
  }
}
