
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































.podcast,
[class*='podcast--'] {
  --podcast-progress: 0%;
}

.magazine-stories {
  @include fluid(
    margin-top,
    (
      xxs: 20px,
      l: 80px,
    )
  );
}
